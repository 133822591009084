/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



				// Window widths
				var winW = $(window).innerWidth();
				// var winH = $(window).innerHeight();
				// var mobileWidth == 768;
				// var menuHeight = $('#menu-top-menu').height();

				$(window).on( 'orientationchange', function () {
					var height = $(window).height();
					var width = $(window).width();
					// console.log('width is : ' + width);
					// console.log('height is : ' + height);
					if (height > width) {
							if(width < 768) {
								window.location.reload();
								// console.log('portrait');
							}
					}
					if (height < width) {
							if(width < 990) {
								window.location.reload();
								// console.log('landscape');
							}
					}
				});


				//-- Smooth Scroll to element on page load
				// Get hash from URL
				$(window).load(function(){
					// Remove the # from the hash, as different browsers may or may not include it
					var hash = location.hash;
					// console.log(hash);
					if(hash !== ''){
						// Clear the hash in the URL
						// location.hash = '';   // delete front "//" if you want to change the address bar
						$('html, body').animate({ scrollTop: $(hash).offset().top - 200 }, 1000);
						$(hash + ".collapse").collapse("show");
					}
				});
				// Clicking on a hash link won't reload the page.
				// Let's do that
				$('footer .contact .header:contains("Get in touch")').on( 'click', function() {
					window.location.href += '#contact-us';
					location.reload();
				});





				// HOMEPAGE

					// Bounce down arrow
					$(window).on( 'scroll', function(){
						$('.bounce').css('opacity', 1 - $(window).scrollTop() / 250); // 250 is fade pixels
					});

					// SLIDER
					//-- Check if background is Star background
					//-- Remove overlay if it is
					//-- Keep overlay if normal pic
					$('.carousel-item').each( function(i){
						var self = $(this);
						var image = self.attr('data-image');
						// console.log(image);
						if(image === 'blue-stars' || image === 'green-stars' || image === 'pink-stars' || image === 'purple-stars') {
							$('.carousel-item[data-image = '+image+']').addClass('no-overlay');
						} else {
							$('.carousel-item[data-image = '+image+']').addClass('overlay');
						}
					});

					// Promo Panel
					if($('.promo-panel > .wp-block-column:last-of-type')){
						$('.promo-panel > .wp-block-column:last-of-type').wrapInner('<div class="promo-panel-content-row"><div class="promo-panel-content"></div></div>');
					}

					// More ways to pay
					if($('.home .more-ways-to-pay figure.wp-block-image')) {
						$(this).each(function() {
							$('.home .more-ways-to-pay .wp-block-image').wrapAll('<div class="payment-icons" /><div class="col-sm-10 col-md-11 col-lg-10" /><div class="row align-items-center justify-content-center" />');
							$('.home .more-ways-to-pay .wp-block-image').addClass('col-6 col-sm-auto');
							// console.log('wrappers');
						});
					}

					// Designers carousel
					var $carousel = $('.home .designers-panel .wp-block-gallery .blocks-gallery-grid').flickity({
						cellAlign: 'left',
						contain: true,
						pageDots: false,
						adaptiveHeight: true,
						wrapAround: true,
						autoPlay: 3000
					});
					$carousel.flickity('playPlayer');
					$carousel.on( 'select.flickity', function() {
						$carousel.flickity('playPlayer');
					});





				// NAVIGATION
				$('.nav-icon').on( 'click', function(){
					$('.navigation').toggleClass('open');
					$('.nav-icon').toggleClass('open');
					$('#nav-icon').toggleClass('open');
					$('.nav-primary').toggleClass('open');
					$('.close-overlay').toggleClass('open');
					if($('.nav-icon').hasClass('open')) {
						// $('.wrap, footer').hide();
						$('body').css({ 'height' : '100vh', 'overflow' : 'hidden' });
					} else {
						// $('.wrap, footer').show();
						$('.additional-links').css({ 'top' : 'inherit' });
						$('body').removeAttr('style');
						// RESET MENU ON CLOSE
						$('.nav').removeClass('open');
						if(winW >= 768) {
							$('.nav-primary').removeClass('wide');
						}
						$('.nav-icon-back.active').css({ 'display' : 'none' });
						$('.nav-icon-back.subactive').css({ 'display' : 'none' });
						$('#menu-top-menu li').each(function() {
							if($(this).hasClass('clicked')) {
								$(this).removeClass('clicked');
							}
						});
					}
				});
				// Clicking on close-overlay closes the menu
				$('.close-overlay').on( 'click', function(){
					$('.navigation').toggleClass('open');
					$('.nav-icon').toggleClass('open');
					$('#nav-icon').toggleClass('open');
					$('.nav-primary').toggleClass('open');
					$('.close-overlay').toggleClass('open');
					$('body').removeAttr('style');
				});


				// Search button and field
				$('div.image').on( 'click', function(){
					$('div.image.open-search').toggleClass('clicked');
					if($('div.image.open-search').hasClass('clicked')){
						// $('.search-form').addClass('clicked');
						$('.open-search').css({ 'display' : 'none' });
						$('.close-search').css({ 'display' : 'inline' });
						$('header.banner, .search-box').addClass('searching');
					} else {
						// $('.search-form');
						$('.open-search').removeClass('clicked').css({ 'display' : 'inline' });
						$('.close-search').css({ 'display' : 'none' });
						$('header.banner, .search-box').removeClass('searching');
					}
				});



				// MAIN MENU

				////////////////////////////////////////////////////////////////////
				//
				//		Start the menu
				//
				////////////////////////////////////////////////////////////////////

				// Set minimum width of menu items on load
				$( window ).load(function() {
					$('li.menu-item').css({ 'min-width' : '350px' });
				});

				// Add the sub menu classes
				$('#menu-top-menu li').each(function() {
					$('#menu-top-menu > li > ul.sub-menu').addClass('subs');
					$('#menu-top-menu li ul.sub-menu > li > ul.sub-menu').removeClass('subs').addClass('subsubs');
				});

				// Change top level link to div
				// Click through to next level of nav
					$('#menu-top-menu > li.menu-item-has-children > a, #menu-top-menu > li.menu-item-has-children > ul.sub-menu > li.menu-item-has-children > a').each(function() {
						$(this).contents().unwrap().wrap('<div class="child-link"><div class="link-text"></div></div>');
					});
							// Add show subs beside top level link
							$('#menu-top-menu li.menu-item-has-children div.child-link').each(function() {
									$(this).append('<div class="show-subs">+</div>');
							});

				//-- Main Menu menu display
					$('#menu-top-menu > li.menu-item-has-children').each(function() {

					//-- Sub menu items
					var submenuID = $(this).attr('id'); // Get menu item ID
					var start = $('#menu-top-menu'); // All menu items
					var menu = $('#menu-top-menu > li.menu-item-has-children'); // All menu items
					var subMenu = $('#menu-top-menu > li.menu-item-has-children.'+submenuID); // Menu item with submenuID as class
					var showSubs = $('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > div.child-link'); // Sub menu toggle item

					//-- Add view-all class to link element containing 'view all' text
					$('a:contains("view all")').addClass('view-all');
					//-- Add home class to link element containing 'home' text
					$('a:contains("Home")').addClass('home');
					//-- Add sale class to link element containing 'sale' text
					$('a:contains("Sale")').addClass('sale');

					var designers = $('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > div.child-link > div.link-text').text();
					// console.log(designers);

					//-- On click, show child sub menu items
					$(showSubs).on( 'click', function() {

						// Expand to wide to show designers menu items
						if(designers === "Designers") {
							$('#menu-top-menu > li.menu-item-has-children.'+submenuID+'').addClass('designers');
							if(winW >= 768) {
								$('.nav-primary').addClass('wide');
							}
						}

						//-- Sub menu toggle
						$(subMenu).toggleClass('clicked');

						//-- Menu item with submenuID as class
						if($(subMenu).hasClass('clicked')) {

								// Set additional links top position
								var subHeight = $('li.clicked > ul.sub-menu.subs').outerHeight(true);
								console.log(subHeight);
								// if(subHeight > 475) {
									$('.additional-links').css({ 'top' : subHeight + 150 });
								// }

								// Add open class to menu
								$(start).addClass('open');

								// Add header above menu
								$('#menu-top-menu > li.menu-item-has-children.'+submenuID+'.clicked').append('<h2>'+$('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > div.child-link > div.link-text').text()+'</h2>');


								// Remove class from all sub menu items
									$(menu).removeClass('clicked');

								// Add to selected menu item only
									$(subMenu).addClass('clicked');

								// Add active class to back arrow
									$('.nav-icon-back.active').css({ 'display' : 'block' });
									$('.nav-icon-back.subactive').css({ 'display' : 'none' });

									$('.nav-icon-back.active').on( 'click', function() {

											// Remove header
											$('#menu-top-menu > li.menu-item-has-children > h2').remove();

											// Hide all buttons
											$('.nav-icon-back.active').css({ 'display' : 'none' });
											$('.nav-icon-back.subactive').css({ 'display' : 'none' });
											$(start).removeClass('open');
											$(menu).removeClass('clicked');
											$('.additional-links').css({ 'top' : 'inherit' });

											if(winW >= 768) {
												$('.nav-primary').removeClass('wide');
											}

									});

							} else {
								// Remove header
									$('#menu-top-menu > li.menu-item-has-children > h2').remove();
								// Remove class from all sub menu items
									$(start).removeClass('open');
									$(menu).removeClass('clicked');
									$('.nav-icon-back').removeClass('active');
									$('.nav-primary').removeClass('wide');
									$('.additional-links').css({ 'top' : 'inherit' });

									if(winW >= 768) {
										$('.nav-primary').removeClass('wide');
									}

							}
					}); // End Subs


					// Sub sub items
					$('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > ul.sub-menu.subs > li.menu-item-has-children').each(function() {

						var subsubmenuID = $(this).attr('id'); // Get sub menu item ID
						var submenu = $('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > ul.sub-menu.subs > li.menu-item-has-children'); // All sub menu items
						var subSubMenu = $('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > ul.sub-menu.subs > li.menu-item-has-children.'+subsubmenuID); // Menu item with subsubmenuID as class
						var showSubSubs = $('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > ul.sub-menu.subs > li.menu-item-has-children.'+subsubmenuID+' > div.child-link'); // Sub sub menu toggle item

						$(showSubSubs).on( 'click', function() {

							$('ul.sub-menu.subs').removeAttr('style');
							$('ul.sub-menu.subsubs').removeAttr('style');

							// Sub sub menu toggle
							$(subSubMenu).toggleClass('clicked');
							// Menu item with subsubmenuID as class
							if($(subSubMenu).hasClass('clicked')) {

								// Set additional links top position
								var subSubHeight = $('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > ul.sub-menu.subs > li.menu-item-has-children.'+subsubmenuID+' >ul.sub-menu.subsubs').outerHeight();
								// if(subSubHeight > 475) {
									$('.additional-links').css({ 'top' : subSubHeight + 150 });
								// }


								if(winW >= 768) {
									$('.nav-primary').addClass('wide');
								}

									$('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > ul.sub-menu.subs').addClass('open');

									// Remove header
									$('#menu-top-menu > li.menu-item-has-children > h2').remove();
									// Add sub header above menu
									$('#menu-top-menu > li.menu-item-has-children.'+submenuID+'.clicked').append('<h2>'+$('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > ul.sub-menu.subs > li.menu-item-has-children.'+subsubmenuID+' > div.child-link > div.link-text').text()+'</h2>');


									// Remove class from all sub menu items
									$(submenu).removeClass('clicked');

									// Add to selected menu item only
									$(subSubMenu).addClass('clicked');

									$('.nav-icon-back.subactive').css({ 'display' : 'block' });
									$('.nav-icon-back.active').css({ 'display' : 'none' });

									// Add active class to back arrow
									$('.nav-icon-back.subactive').on( 'click', function() {

										if(winW >= 768) {
											$('.nav-primary').removeClass('wide');
										}

										$('.additional-links').css({ 'top' : 'inherit' });

										// Show active button again
										$('.nav-icon-back.active').css({ 'display' : 'block' });
										$('.nav-icon-back.subactive').css({ 'display' : 'none' });
										$('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > ul.sub-menu.subs').removeClass('open');
										$(subSubMenu).removeClass('clicked');

										// Remove header
										$('#menu-top-menu > li.menu-item-has-children > h2').remove();
										// Add header above menu
										$('#menu-top-menu > li.menu-item-has-children.'+submenuID+'.clicked').append('<h2>'+$('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > div.child-link > div.link-text').text()+'</h2>');

									});

								} else {

									if(winW >= 768) {
										$('.nav-primary').removeClass('wide');
									}

									$('.additional-links').css({ 'top' : 'inherit' });
									$('#menu-top-menu > li.menu-item-has-children.'+submenuID+' > ul.sub-menu.subs').removeClass('open');

									// Remove class from all sub menu items
									$(subSubMenu).removeClass('clicked');

								}
						});
					}); // END Sub subs

				}); // END Main Menu Display


				// Back to top button
				$(window).on( 'scroll', function() {
					if($(this).scrollTop() > 200) {
						$('.backtotop').addClass('visible');
					} else if($(this).scrollTop() < 200) {
						$('.backtotop').removeClass('visible');
					}
				});
				$('.backtotop').on('click', function(){
					$('html, body').animate({ scrollTop: 0 }, 'slow');
					return false;
				});





				// PAGES
				//-- Smooth scroll to expanded accordion
				$('.collapse').on('shown.bs.collapse', function(e) {
					var $card = $(this).closest('.card');
					$('html,body').animate({
						scrollTop: $card.offset().top -50
					}, 500);
				});





				// WOOCOMMERCE

				// Single Product Page - Image fixes
				var wooImageWidth = $('.woocommerce-product-gallery').innerWidth();
				$('.woocommerce-product-gallery > .flex-viewport').css({ 'height' : wooImageWidth+' !important' });

				// Single Product Page - Check for additional information tab. Hide if none. Make Active if there.
				if($('.additional_information_tab.active')) {
					$('.woocommerce-tabs .tabs').hide();
					$('.woocommerce-tabs > div#tab-description').hide();
				} else {
					$('.woocommerce-tabs').hide();
				}


				// Single Product Page - Related products carousel
				$('.related.products ul.products').flickity({
					pageDots: false,
					adaptiveHeight: true,
					contain: true,
					wrapAround: true,
					groupCells: true,
					autoPlay: 3000
				});



				// GRAPHICS
				// -- Change images with svg class to svg
				jQuery('img.svg').each(function(){
					var $img = jQuery(this);
					var imgID = $img.attr('id');
					var imgClass = $img.attr('class');
					var imgURL = $img.attr('src');
						jQuery.get(imgURL, function(data) {
							// Get the SVG tag, ignore the rest
								var $svg = jQuery(data).find('svg');
							// Add replaced image's ID to the new SVG
								if(typeof imgID !== 'undefined') {
									$svg = $svg.attr('id', imgID);
								}
							// Add replaced image's classes to the new SVG
								if(typeof imgClass !== 'undefined') {
									$svg = $svg.attr('class', imgClass+' replaced-svg');
								}
							// Remove any invalid XML tags as per http://validator.w3.org
								$svg = $svg.removeAttr('xmlns:a');
							// Replace image with new SVG
								$img.replaceWith($svg);
						}, 'xml');
				});




			},
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
